import useAuth from '../../core/hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';
import { userHasPermission } from '../../core/utils';

import AppLoader from '../AppLoader/AppLoader';
import ErrorPage from '../ErrorPage/ErrorPage';


const ProtectedRoute = ({permission, children}) => {

  const { userToken, authenticatedUser, authenticatedUserLoading } = useAuth();

  if(!userToken) return <Navigate to="/login" replace />;
  if(permission && !authenticatedUser && authenticatedUserLoading) return <AppLoader type="PAGE" />;
  if(permission && !userHasPermission(authenticatedUser, permission)) return <ErrorPage name="Unauthorized" />;
  return children ? children : <Outlet />;
};

export default ProtectedRoute;