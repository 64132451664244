import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppUI from '../../../core/hooks/useAppUI';
import useAuth from '../../../core/hooks/useAuth';

const useAppHeader = () => {

  const {pageName, pageIsLoading, appTimezone, setAppTimezone} = useAppUI();
  const { userToken, signOut, authInterception, authenticatedUser, getUserData } = useAuth();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isTimezoneModalOpen, setIsTimezoneModalOpen] = useState(false);

  const toggleNav = (e) => {
    e.stopPropagation();
    const ddowns = document.querySelectorAll('.dropdown.show, .dropdown-menu.show');
    ddowns.forEach(dd => {dd.classList.remove('show')});
    setIsNavOpen(!isNavOpen);
  }


  useEffect(() => {
    if(!appTimezone) setAppTimezone('UTC');
  }, [appTimezone, setAppTimezone]);


  useEffect(() => {
    authInterception();

    const closeNav = () => { setIsNavOpen(false); }
    document.addEventListener('click', closeNav);

    return () => {
      document.removeEventListener('click', closeNav);
    }
  }, []); //eslint-disable-line


  //transform bs link to router dom link
  const navigate = useNavigate();
  const routerNavigate = (e) => {
    e.preventDefault();
    navigate(e.target.attributes.href.nodeValue);
  }


  useEffect(() => {
    userToken && getUserData();
  }, [userToken, getUserData]);



  

  return {
    pageName,
    pageIsLoading,
    appTimezone,
    setAppTimezone,

    userToken,
    signOut,

    isNavOpen,
    toggleNav,

    isTimezoneModalOpen, 
    setIsTimezoneModalOpen,

    routerNavigate,
    authenticatedUser
  }
};

export default useAppHeader;