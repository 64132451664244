import { RobotMessage } from "./RobotMessage";
import { UserMessage } from "./UserMessage";

export const Messages = ({ messages }) => {
  return messages.map((message, index) => {
    if (message.isRobot) {
      return <RobotMessage key={index} message={message} />;
    }
    return <UserMessage key={index} message={message} />;
  });
};
