import useAppUI from '../../../core/hooks/useAppUI';
import { IconRobot } from "./Icons";

export const RobotMessage = ({ message }) => {
  const classNames = ["text"];

  const { setTheaterImgUrl } = useAppUI();

  if(message.hasError) classNames.push("error")
  else if(message.isFile) classNames.push("file");
  else if(message.isWriting) classNames.push("is-writing");

  return (
    <div className="box">
      <span className="icon">
        <IconRobot />
      </span>
      <div className="message">
        <p className={classNames.join(" ")}>
          <span>{message.text}</span>
          {message?.attachments?.map((a, idx) => {
            const imgSource = `data:${a.type};charset=utf-8;base64, ${a.value}`;
            return <label key={idx}>
              <img key={idx} src={imgSource} alt="Sapient Chart" onClick={()=>setTheaterImgUrl(imgSource)} />
            </label>
          })}
        </p>
        {!message.isWriting && <div className="date">{message.date}</div>}
      </div>
    </div>
  );
};
