import axios from 'axios';
import getUnixTime from 'date-fns/getUnixTime';
const jwtSign = require('jwt-encode');

const httpSapient = axios.create({
  baseURL: process.env.REACT_APP_MCA_CHAT_URL
});

httpSapient.interceptors.request.use(
  (config) => {
    const token = jwtSign({from:'mca', exp:getUnixTime(new Date())+120}, process.env.REACT_APP_CHAT_AUTH_KEY);
    config.headers.common['AUTH-KEY'] = token; 
    return config;
  }
);

export default httpSapient;