import {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AppUIContext} from '../../core/contexts/AppUIProvider';

import Container from 'react-bootstrap/Container';

import './AppLoader.css';

const AppLoader = ({id, type: loaderType, classNames}) => {
  const {setPageIsLoading} = useContext(AppUIContext);
  useEffect(() => {
    if(loaderType === 'PAGE') setPageIsLoading(true);
  }, []); // eslint-disable-line

  return (
    <Container id={id} className={`loadingComponent ${classNames || ''}`} as="section">
      <div></div>
    </Container>
  )
};

AppLoader.propTypes = {
  type: PropTypes.oneOf(['PAGE', 'EMBEDDED']).isRequired
}

export default AppLoader;