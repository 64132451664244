import { DragAndDrop } from "./DragAndDrop";

export const ChatContainer = ({ isChatbotOpened, children, onDropFile }) => {
  return (
    <div className={isChatbotOpened ? "show-chatbot" : ""}>
      <DragAndDrop onDropFile={onDropFile} className="chatbot">
        {children}
      </DragAndDrop>
    </div>
  );
};
