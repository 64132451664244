import { IconUser } from "./Icons";

export const UserMessage = ({ message }) => {
  return (
    <div className="box user">
      <span className="icon">
        <IconUser />
      </span>
      <div className="message">
        <p className="text">{message.text}</p>
        <div className="date">{message.date}</div>
      </div>
    </div>
  );
};
