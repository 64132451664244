import { useEffect } from 'react';
import useAuth from '../../../core/hooks/useAuth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import loginSchema from './loginValidation';


const useLogin = () => {

  const {
    authLoading, authError, userToken, signIn,
    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin,
    microsoftAzureSignIn
  } = useAuth();

  const { register, handleSubmit, formState: { isSubmitted, errors } } = useForm({ resolver: yupResolver(loginSchema) });

  const loginSubmit = data => signIn({login:data.username, password:data.password});

  useEffect(() => {
    microsoftAzureSignIn();
  }, [microsoftAzureSignIn]);

  return {
    authLoading,
    authError,
    userToken,
    loginSubmit,
    register,
    handleSubmit,
    isSubmitted,
    errors,

    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin
  }

}

export default useLogin;