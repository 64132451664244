import { useEffect, useState, useRef, useCallback } from "react";
import { Client } from "paho-mqtt";
import { getTime } from "date-fns";

const MQTT_BROKER_URL = process.env.REACT_APP_BROKER_HOST || null;
const MQTT_USERNAME   = process.env.REACT_APP_BROKER_USER || null;
const MQTT_PASSWORD   = process.env.REACT_APP_BROKER_PASSWORD || null;

const useMqtt = () => {

  const clientRef = useRef(null);
  const subscriptionsRef = useRef({});

  const [brokerClient, setBrokerClient] = useState(null);

  useEffect(() => {
    if(!clientRef.current){
      clientRef.current = new Client(MQTT_BROKER_URL, "MCA-MIN-REPORTS-"+getTime(new Date()));
      if(!clientRef.current.isConnected()){
        //console.log('Broker connecting..', clientRef.current.clientId);
        clientRef.current.connect({
          userName: MQTT_USERNAME,
          password: MQTT_PASSWORD,
          onSuccess: () => {
            //console.log('Broker connected..', clientRef.current.clientId);
            setBrokerClient(clientRef.current);
          },
        });
      }
    }

    clientRef.current.onMessageArrived = (message) => {
      //console.log('The message that has arrived', message);
      if(subscriptionsRef.current[message.topic]) subscriptionsRef.current[message.topic](message.payloadString);
    }

    return () => {
      if(clientRef.current && clientRef.current.isConnected()) clientRef.current.disconnect(() => {console.log('Broker disconnected..');});
    };
  }, []);

  const brokerSubscribe = useCallback((topic, callbackFunction) => {
    if(clientRef.current){
      clientRef.current.subscribe(topic, {
        onSuccess: () => {
          //console.log('Subscribed to topic', topic);
          if(!subscriptionsRef.current[topic]) subscriptionsRef.current[topic] = callbackFunction;
        }
      });
    }
  }, []);

  const brokerPublish = useCallback((topic, payload, qos=0, retained=false) => {
    if(clientRef.current){
      clientRef.current.publish(topic, payload, qos, retained);
    }
  }, []);

  return {
    brokerClient,
    brokerSubscribe,
    brokerPublish
  };
};

export default useMqtt;