import { useState, useEffect } from 'react';
import timezonesService from '../../../../../pages/Settings/pages/Timezones/logic/timezonesService';


const useTimezoneModal = (setIsOpen, appTimezone, setAppTimezone) => {

  const [timezoneData, setTimezoneData] = useState();
  const [timezoneLoading, setTimezoneLoading] = useState(false);
  const [timezoneError, setTimezoneError] = useState();

  const [selectedTimezone, setSelectedTimezone] = useState(appTimezone);
  
  const getTimezones = async () => {
    setTimezoneLoading(true);
    setTimezoneError();

    try{
      const response = await timezonesService.getTimezones();
      if(response.data) setTimezoneData(response.data);
      else setTimezoneData();
    }catch(error){
      setTimezoneError('An error has occurred !');
    }finally{
      setTimezoneLoading(false);
    }
  }
  
  const handleTimezoneChange = (e) => setSelectedTimezone(e.target.value);

  const saveAndCloseModal = (e) => {
    setAppTimezone(selectedTimezone);
    setIsOpen(false);
  }
  const closeModal = () => setIsOpen(false);

  
  useEffect(() => {
    getTimezones();
  }, []);





  return {
    timezoneData,
    timezoneLoading,
    timezoneError,

    selectedTimezone, 
    handleTimezoneChange,

    saveAndCloseModal,
    closeModal
  }
};

export default useTimezoneModal;