import httpSapient from '../../../core/apis/httpSapient';

const errorMessage = [{"attachments":[],"message":"Sapient can't handle this request, please try again in a while."}]

const sendMessageToRobot = async (user_message, user_id) => {
  try {
    const response = await httpSapient({
      method: "POST",
      data: {
        user_id,
        user_message,
        mqtt_topic: 'mca/' + user_id
      },
    });

    if(response.status !== 200) return {
      data: errorMessage,
      hasError: true
    };

    return {
      data: "Writing...",
      hasError: false
    };
  } catch (error) {
    console.error(`Error : ${error.message}`);
    return {
      data: errorMessage,
      hasError: true,
    };
  }
};

const sendFileToRobot = async (file) => {
  const formData = new FormData();
  formData.append("fileModel", file);

  try {
    const response = await httpSapient({
      method: "POST",
      url: "/upload_file",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 200) {
      return { data: response.data, hasError: false };
    } else {
      return { data: errorMessage, hasError: true };
    }
  } catch (error) {
    console.error(`Error : ${error.message}`);
    return {data: errorMessage, hasError: true};
  }
};

export const chatbotService = {
  sendMessageToRobot,
  sendFileToRobot,
};
