import React, { useEffect } from 'react';
import useAppUI from '../../core/hooks/useAppUI';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import './Layout.css';

const Layout = ({name, className, children}) => {
  const {setPageName, setPageIsLoading} = useAppUI();
  
  useEffect(() => {
    setPageIsLoading(false);
    setPageName(name);
  }, []); // eslint-disable-line

  return <Container className={`avxPageWrapper ${className || ''}`} as="section">
    {children}
  </Container>
};

Layout.propTypes = {
  name: PropTypes.string.isRequired
};

export default Layout;