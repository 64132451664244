import useTimezoneModal from './logic/useTimezoneModal';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';


import './TimezoneModal.css';

const TimezoneModal = ({isOpen, setIsOpen, appTimezone, setAppTimezone}) => {

  const {
    timezoneData, timezoneLoading, timezoneError,
    selectedTimezone, handleTimezoneChange,
    saveAndCloseModal, closeModal
  } = useTimezoneModal(setIsOpen, appTimezone, setAppTimezone);

  const TimezonesSelect = () => {
    if(timezoneLoading) 
      return <div className="timezoneLoading">
        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
      </div>;
    if(timezoneError) return <Alert variant="danger">{timezoneError}</Alert>
    if(timezoneData && timezoneData.length) 
      return <Form.Select value={selectedTimezone} onChange={handleTimezoneChange}>
        { timezoneData.map((tz, idx) => <option key={'tz'+idx} value={tz.timezone} >{tz.timezone}</option>) }
      </Form.Select>;
    else return <Alert variant="warning">No time zones available!</Alert>
  };

  const disabledSave = (timezoneLoading || timezoneError || !(timezoneData && timezoneData.length)) ? true : false;

  return (
    <Modal className="timezoneModal" show={isOpen} centered backdrop="static" keyboard={false} animation={false} onHide={() => setIsOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Select your time zone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TimezonesSelect /> 
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Close</Button>
        <Button variant="primary" disabled={disabledSave} onClick={saveAndCloseModal}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  )
};

export default TimezoneModal;