import { ToastContainer } from 'react-toastify';

import './AppFooter.css';
import 'react-toastify/dist/ReactToastify.css';

const AppFooter = () => {
  return <>
    <ToastContainer position="bottom-left" autoClose={5000} closeOnClick={true} closeButton={true} />
    <footer className="avxAppFooter">
      <p>Copyright &copy; {new Date().getFullYear()} Avaxia. All Rights Reserved.</p>
      <p>Powered by <a href="https://www.avaxiagroup.com" target="_blank" rel="noopener noreferrer">Avaxia</a></p>
    </footer>
  </>
};

export default AppFooter;