import { createContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "../apis/httpMsal";

const msalInstance = new PublicClientApplication(msalConfig);

export const AppAuthContext = createContext();

const AppAuthProvider = ({ children }) => {

  const [userToken, setUserToken] = useLocalStorage('mca-x-act');
  const [refreshToken, setRefreshToken] = useLocalStorage('mca-x-rft');
  const [authenticatedUser, setAuthenticatedUser] = useState();

  const providerValue = {
    userToken,
    setUserToken,
    refreshToken,
    setRefreshToken,
    authenticatedUser,
    setAuthenticatedUser
  }

  return (
    <AppAuthContext.Provider value={ providerValue }>
      <MsalProvider instance={msalInstance}>
        {children}
      </MsalProvider>
    </AppAuthContext.Provider>
  )
};

export default AppAuthProvider;