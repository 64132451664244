import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import notificationService from './notificationService';
import useAppUI from '../../../../../core/hooks/useAppUI';

const useNotificationCenter = (user) => {
  
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  const getNotificationHistory = async () => {
    setIsLoading(true);
    setError();

    try{
      const { data } = await notificationService.getNotificationHistory();
      if(data) setNotifications(data);
      else setNotifications([]);
    }catch(error) {
      setError('An error has occurred !');
    }finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationHistory();
  }, []);

  const { brokerClient, brokerSubscribe, brokerPublish } = useAppUI();
  const [newAlert, setNewAlert] = useState(null);

  useEffect(() => {
    if(user && brokerClient && brokerClient.isConnected()){
      brokerSubscribe(user.id + '/notifyInCenter', (msg) => {
        toast.info(JSON.parse(msg).message);
        setNewAlert(msg);
      });
    }
  }, [user, brokerClient, brokerSubscribe]);

  useEffect(() => {
    if(newAlert){
      setNotifications(n => [JSON.parse(newAlert), ...n]);
    }
  }, [newAlert]);

  const dDownRef = useRef(null);
  const dDownMenuRef = useRef(null);
  const navigate = useNavigate();

  const handleNotificationClick = (e, index, notification, redirect=false) => {
    if(notification.is_unread && brokerClient && brokerClient.isConnected()){
      brokerPublish('mca-core/setNotificationAsRead', notification.id.toString()); 
      setNotifications(old => old.map((n, i) => i===index ? {...n, is_unread:0} : n));
    }
    if(!redirect) return e.stopPropagation();
    dDownRef.current.classList.remove('show');
    dDownMenuRef.current.classList.remove('show');
    navigate(notification.endpoint);
  };

  return {
    dDownRef, dDownMenuRef,
    notifications, isLoading, error,
    handleNotificationClick
  }
};

export default useNotificationCenter;