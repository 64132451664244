import { useRef, useState, useEffect, useLayoutEffect } from "react";
import { isMobile } from "react-device-detect";
import useAuth from "../../core/hooks/useAuth";
import useAppUI from "../../core/hooks/useAppUI";
import { chatbotService } from "./logic/chatbotService";
import { getCurrentDate } from "./logic/utils";
import { ChatContainer } from "./components/ChatContainer";
import { ChatHeader } from "./components/ChatHeader";
import { Chatbox } from "./components/ChatBox";
import { ChatInput } from "./components/ChatInput";
import { ChatbotToggler } from "./components/ChatbotToggler";
import { Messages } from "./components/Messages";
import "./Chatbot.css";

const Chatbot = () => {
  const { appTimezone, brokerClient, brokerSubscribe } = useAppUI();
  const { authenticatedUser } = useAuth();
  const [newSapientMessage, setNewSapientMessage] = useState(null)

  const [messages, setMessages] = useState([]);
  const [robotResponse, setRobotResponse] = useState({ message: [] });
  const [chatInputText, setChatInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChatbotOpened, setIsChatbotOpened] = useState(false);
  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);

  // this is a workaround to fix the chatbox height problem on mobile devices when the keyboard is opened.
  useLayoutEffect(() => {
    const handleViewportChange = () => {
      const viewport = window.visualViewport;
      const chatBotHeaderHeight = 40;
      chatboxRef.current.style.height = viewport.height - chatBotHeaderHeight + "px";
      scrollToBottom();
    };

    if (isMobile) {
      handleViewportChange();
      window.visualViewport.addEventListener("scroll", handleViewportChange);
      window.visualViewport.addEventListener("resize", handleViewportChange);
    }
    return () => {
      if (isMobile) {
        window.visualViewport.removeEventListener("scroll",handleViewportChange);
        window.visualViewport.removeEventListener("resize",handleViewportChange);
      }
    };
  }, []);


  // useLayoutEffect(() => {
  //   const handleViewportChange = (e) => {
  //     console.log('the target', e.target);
  //     if(!e.target || e.target.id !== 'chatInputText') return;
  //     setTimeout(() => {
  //       const viewportHeight = window.visualViewport.height;
  //       console.log('VH', viewportHeight);
  //       chatboxRef.current.style.height = viewportHeight - 40 + 'px';
  //       scrollToBottom();
  //     }, 1000);
  //   }

  //   if(isMobile){
  //     //handleViewportChange();
  //     document.addEventListener('focusin', handleViewportChange);
  //     document.addEventListener('focusout', handleViewportChange);
  //   }

  //   return () => {
  //     document.removeEventListener('focusin', handleViewportChange);
  //     document.removeEventListener('focusout', handleViewportChange);
  //   }
  // }, []);

  useLayoutEffect(() => {
    scrollToBottom();
    focusChatInput();
  }, [messages]);

  //console.log('MESSAGES LIST', messages);

  useEffect(() => {
    if(robotResponse.message && robotResponse.message.length) setMessages(_messages => {
      let messagesList = [..._messages];
      messagesList = messagesList.filter(msg => !msg.isWriting);
      
      if(robotResponse.message === "Writing..."){
        messagesList.push({
          date: getCurrentDate(appTimezone),
          text: <TypingBot />,
          attachments: [],
          isRobot: true,
          hasError: false,
          isFile: false,
          isWriting: true
        });
      }else{
        messagesList.push( ...robotResponse.message.map(
          single => {
            const newMsg = {
              date: getCurrentDate(appTimezone),
              text: single.message,
              attachments: single.attachments,
              isRobot: true,
              hasError: robotResponse.hasError,
              isFile: robotResponse.isFile,
              isWriting: robotResponse.isWriting
            };
            return newMsg;
          }
        ));
      }
      return messagesList;
    });
    setIsLoading(false);
  }, [robotResponse, appTimezone]);

  const sendMessageToRobot = async () => {
    if (chatInputText.trim().length === 0) return;
    setIsLoading(true);
    setMessages([
      ...messages,
      {
        date: getCurrentDate(appTimezone),
        text: chatInputText,
        isRobot: false,
        hasError: false,
        isWriting: false
      },
    ]);
    setChatInputText("");
    const response = await chatbotService.sendMessageToRobot(chatInputText, authenticatedUser.id);
    setRobotResponse({
      message: response.data, 
      hasError: response.hasError,
      isWriting: true
    });
  };

  useEffect(() => {
    if(brokerClient && brokerClient.isConnected()){
      brokerSubscribe('mca/' + authenticatedUser.id, (msg) => setNewSapientMessage(msg));
    }
  }, [authenticatedUser, brokerClient, brokerSubscribe]);

  useEffect(() => {
    if(newSapientMessage && newSapientMessage.length){
      setRobotResponse({
        message: JSON.parse(newSapientMessage),
        hasError: false,
        isWriting: false
      });
    }
  }, [newSapientMessage]);

  const onFileUpload = async (file) => {
    if (!file) return;
    setIsLoading(true);
    const response = await chatbotService.sendFileToRobot(file);
    setRobotResponse({
      message: response.data,
      hasError: response.hasError,
      isFile: true,
    });
  };

  const onChatToggle = () => {
    setIsChatbotOpened(!isChatbotOpened);
    if(!isMobile) focusChatInput(!isChatbotOpened);
  };

  const onChangeChatInput = (event) => {
    setChatInputText(event.target.value);
  };

  const onClickSend = () => {
    sendMessageToRobot();
  };

  const onKeyDownChatInput = (event) => {
    if(event.key === "Enter") sendMessageToRobot();
  };

  const scrollToBottom = () => {
    if(chatboxRef.current) chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  };

  const focusChatInput = (isOpen) => {
    if(chatInputRef.current) return isOpen ? chatInputRef.current.focus() : chatInputRef.current.blur();
  };

  return (
    <>
      <ChatContainer isChatbotOpened={isChatbotOpened} onDropFile={onFileUpload}>
        <ChatHeader title="Sapient" onClickMinimize={onChatToggle} />
        <Chatbox chatboxRef={chatboxRef}>
          <Messages messages={messages} />
        </Chatbox>
        <ChatInput
          chatInputRef={chatInputRef}
          value={chatInputText}
          isLoading={isLoading}
          onFileUpload={onFileUpload}
          onChange={onChangeChatInput}
          onClick={onClickSend}
          onKeyDown={onKeyDownChatInput}
        />
      </ChatContainer>

      <ChatbotToggler isChatbotOpened={isChatbotOpened} onOpenChat={onChatToggle} />
    </>
  );
};


const TypingBot = () => {
  return <span className="bot-typing">
    <span className="bot-dot"></span>
    <span className="bot-dot"></span>
    <span className="bot-dot"></span>
  </span>;
};

export default Chatbot;
