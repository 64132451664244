import { IconDash } from "./Icons";

export const ChatHeader = ({onClickMinimize, title}) => {
  return (
    <header>
      <div>{title}</div>
      <div onClick={onClickMinimize}><IconDash /></div>
    </header>
  );
};
