// import { FileUploader } from "./FileUploader";
import { IconSend } from "./Icons";

export const ChatInput = ({
  value,
  isLoading,
  onChange,
  onKeyDown,
  onClick,
  chatInputRef,
  // onFileUpload,
}) => {
  return (
    <div className={"chat-input"}>
      <textarea
        id="chatInputText"
        placeholder="Enter a message..."
        spellCheck="false"
        required
        onChange={onChange}
        value={value}
        disabled={isLoading}
        onKeyDown={onKeyDown}
        ref={chatInputRef}
      />
      {/* {!isLoading && <FileUploader handleFileUpload={onFileUpload} />} */}
      <div onClick={onClick}>
        {isLoading && <div className="spinner" />}
        {!isLoading && <IconSend />}
      </div>
    </div>
  );
};