import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { IconBell } from '../../../catalog/icons/icons';
import useNotificationCenter from './logic/useNotificationCenter';
import useAppUI from '../../../../core/hooks/useAppUI';

import './NotificationCenter.css';

const NotificationCenter = ({user}) => {

  const {
    dDownRef, dDownMenuRef,
    notifications, isLoading, error,
    handleNotificationClick
  } = useNotificationCenter(user);

  const unreadNbr = notifications.filter(n => n.is_unread).length;
  
  return <Dropdown className="navNotificationDrop" ref={dDownRef}>
    <Dropdown.Toggle as="button" id="notifToggler" className="navbar-toggler">
      <IconBell />
      {unreadNbr ? <Badge pill bg='danger'>{unreadNbr}</Badge>: ''}
    </Dropdown.Toggle>
    <Dropdown.Menu variant="dark" align="end" ref={dDownMenuRef}>
      <Dropdown.Header>Notification Center</Dropdown.Header>
      <NotificationBody data={notifications} isLoading={isLoading} error={error} onClick={handleNotificationClick} />
    </Dropdown.Menu>
  </Dropdown>
};

export default NotificationCenter;



// --- Sub-components ---------------------------------------------------------------------------------------------------- /

const NotificationBody = ({data, isLoading, error, onClick}) => {

  const { getAppTimezonedDate } = useAppUI();

  if(isLoading) return <Dropdown.ItemText className="itemLoading">
    <Spinner animation='grow' size='sm' role='status' />{' '}
    <Spinner animation='grow' size='sm' role='status' />{' '}
    <Spinner animation='grow' size='sm' role='status' />
  </Dropdown.ItemText>;

  if(error) return <Dropdown.ItemText className="itemError">{error}</Dropdown.ItemText>;

  if(data && data.length) return <div className="notificationInner">
  {
    data.map((noti, nidx) => <Dropdown.ItemText as='div' key={`not-${nidx}`} className="itemNotification" onClick={(e)=>onClick(e, nidx, noti, true)}>
      <div>{noti.is_unread ? <span className="isUnread" onClick={(e)=>onClick(e, nidx, noti)}></span> : ''}</div>
      <div>
        <p>{noti.message}</p>
        <footer>
          <abbr>{getAppTimezonedDate(noti.time, 'Y-MM-dd - hh:mm aa', true)}</abbr>
        </footer>
      </div>
    </Dropdown.ItemText>)
  }
  </div>

  else return <Dropdown.ItemText className="itemEmpty">No notification</Dropdown.ItemText>;
};