import useLogin from './logic/useLogin';
import { Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { IconMicrosoft } from '../../components/catalog/icons/icons';

import './LoginPage.css';


const LoginPage = () => {
  
  const {
    authLoading, authError, userToken, loginSubmit, 
    register, handleSubmit, isSubmitted, errors,

    isMicrosoftAzureAuthLoading,
    handleMicrosoftAzureLogin
  } = useLogin();

  if(userToken) return <Navigate to="/" />

  return (
    <section className="avxLgPgWrapper">
      <div className="avxLgContainer">
        <div className="avxLgFeedback">
          {authError && <p>{authError}</p>}
        </div>
        <div className="avxLgInner">
          <header className="mb-4">
            <span></span>
            <h2>Login</h2>
          </header>
          <Form id="avxLgForm" onSubmit={handleSubmit(loginSubmit)} noValidate>
            <div className="avxLgFormInner">
              <Form.Group className="mb-2" controlId="userLogin">
                <Form.Label>Email*</Form.Label>
                <Form.Control type="email" placeholder="Enter email"  {...register('username')} isInvalid={isSubmitted && errors.username} />
              </Form.Group>

              <Form.Group controlId="userPassword">
                <Form.Label>Password*</Form.Label>
                <Form.Control type="password" placeholder="Password" {...register('password')} isInvalid={isSubmitted && errors.password} autoComplete="off" />
              </Form.Group>
            </div>

            <Button disabled={authLoading} size="lg" type="submit">
              { authLoading 
                ? <>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> 
                  </>
                : 'Sign in with email' 
              }
            </Button>
            <Button className="msLoginBtn" size="lg" disabled={isMicrosoftAzureAuthLoading} onClick={handleMicrosoftAzureLogin}>
              { isMicrosoftAzureAuthLoading 
                ? <>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> 
                  </>
                : <>
                    <IconMicrosoft />
                    Sign in with Microsoft
                  </>
              }
            </Button>
          </Form>
        </div>
      </div>

    </section>
  )
};

export default LoginPage;