import { useRef, useState } from "react";

export const DragAndDrop = ({ onDropFile, children, className }) => {
  const [insideTarget, setInsideTarget] = useState(false);
  const isDragging = useRef(false);
  const noDraggableDiv = useRef(null);

  const classNames = [className];

  if (isDragging.current) {
    classNames.push("dragging");
  }

  const onDragEnter = (e) => {
    noDraggableDiv.current.classList.add("no-drag");
    isDragging.current = true;
    e.preventDefault();
    e.stopPropagation();
    setInsideTarget(true);
  };

  const onDragLeave = (e) => {
    if (insideTarget) {
      noDraggableDiv.current.classList.remove("no-drag");
      isDragging.current = false;
    }
    e.preventDefault();
    e.stopPropagation();
    setInsideTarget(false);
  };

  const onDrop = (e) => {
    isDragging.current = false;
    noDraggableDiv.current.classList.remove("no-drag");
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    onDropFile(file);
    setInsideTarget(false);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInsideTarget(true);
  };

  return (
    <div
      className={classNames.join(" ")}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <div ref={noDraggableDiv}>{children}</div>
    </div>
  );
};
