import axios from "axios";

const httpMain = axios.create({
  baseURL: process.env.REACT_APP_MCA_CORE_URL
});

httpMain.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem('mca-x-act');
    const refreshToken = window.localStorage.getItem('mca-x-rft');
    if(accessToken) config.headers.common['ACT'] = accessToken;
    if(refreshToken) config.headers.common['RFT'] = refreshToken;

    return config;
  }
);


export default httpMain;