import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';

const ErrorPage = ({name}) => {
  const msgCatalog = {
    "Unauthorized" : "🔐 You don't have access to this ressource",
    "Page not found" : "⚠️ It looks like you're lost"
  };
  const pageMessage = msgCatalog[name];
  return (
    <Layout name={name}>
      <h2>{pageMessage}</h2>
      <Link to="/">Go back home</Link>
    </Layout>
  )
};

export default ErrorPage;