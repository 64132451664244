export const outerHeight = (el) => {
  let height = 0
  if(el){
    height = el.offsetHeight
    const style = getComputedStyle(el)
    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
  }
  return height
};

export const processNodeHeight = (substractClassesTab, targetID, additionPxs = 15) => {
  const winH = window.innerHeight
  const totalSubstract = substractClassesTab
    .map( classString => outerHeight(document.querySelector('.'+classString)) )
    .reduce((acc, singleH)=> acc+singleH, 0) + additionPxs;
  document.getElementById(targetID) && ( document.getElementById(targetID).style.height = (winH - totalSubstract)+'px' )
};

export const makeDelay = (callback, ms) => {
  let timerDelay = 0;
  clearTimeout(timerDelay);
  timerDelay = setTimeout(callback, ms || 0);
};

export const slugify = (string) => {
  if(!string) return '';

  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
};

export const formatNbr = num => {
  const num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return num_parts.join(".");
};

export const userHasPermission = (user, permission, permissionsField = 'permissions') => (!user || !user[permissionsField] || !user[permissionsField].includes(permission)) ? false : true;