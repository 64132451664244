import { useContext } from 'react';
import { AppUIContext } from '../../core/contexts/AppUIProvider';
import { IconClose } from '../Chatbot/components/Icons';

import './Theater.css';

const Theater = () => {

  const { theaterImgUrl, setTheaterImgUrl } = useContext(AppUIContext);

  if(!theaterImgUrl) return null;

  return <section className="theaterBackdrop">
    <span className="theaterClose" onClick={()=>setTheaterImgUrl()}>
      <IconClose />
    </span>
    <img src={theaterImgUrl} alt="Sapient Chart" />
  </section>
};

export default Theater;