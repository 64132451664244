import httpMain from "../../../../../core/apis/httpMain";

const getNotificationHistory = async () => {
  try{
    const response = await httpMain({
      method: 'GET',
      url: '/notifications'
    });

    return response;
  }catch(e){
    throw e;
  }
};

const notificationService = {
  getNotificationHistory
};

export default notificationService;

